
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { WandIcon } from "vue-tabler-icons";
  import { apiCheckRecoverHash, apiUpdatePasswordByHash } from "../api";

  @Component({
    components: { LzButton, WandIcon }
  })
  export default class RecoverHash extends Vue {
    recoverForm = {
      password: "",
      password_confirm: ""
    };

    loadingPostRecover = false;
    showScreen = false;
    recoverCompleted = false;

    mounted() {
      if (!this.$route.params.hash) {
        return this.$router.push({ name: "Signin" });
      }
      this.showScreen = true;
      apiCheckRecoverHash({
        hash: this.$route.params.hash
      })
        .then(async (response: any) => {
          this.showScreen = true;
        })
        .catch((error: any) => this.handleError(error))
        .finally(() => (this.loadingPostRecover = false));
    }

    onRecoverSubmit() {
      this.loadingPostRecover = true;

      apiUpdatePasswordByHash({
        hash: this.$route.params.hash,
        password: this.recoverForm.password
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(async (response: any) => {
          this.recoverCompleted = true;
        })
        .catch((error: any) => this.handleError(error))
        .finally(() => (this.loadingPostRecover = false));
    }

    handleError(error: any): void {
      switch (error.response.status) {
        case 400:
        default:
          this.$router.push({ name: "Signin" });
      }
    }
  }
